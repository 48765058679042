import { motion } from "framer-motion";
import * as React from "react";
import styled from "styled-components";
import SEO from "../components/seo";

const IndexPage: React.FC = () => {
	const variants = {
		visible: { opacity: 1, y: 0 },
		hidden: { opacity: 0, y: 20 },
	};
	return (
		<React.Fragment>
			<SEO title="KitchenShelf - Contact" />
			<ContactWrapper>
				<div className="hero-content">
					<motion.h3
						initial="hidden"
						animate="visible"
						variants={variants}
						transition={{ ease: "easeOut", duration: 0.8, delay: 0.5 }}
					>
						Contact us
					</motion.h3>
					<motion.h1
						initial="hidden"
						animate="visible"
						variants={variants}
						transition={{ ease: "easeOut", duration: 0.8, delay: 1 }}
					>
						<a href="mailto:support@kitchenshelf.co.uk">
							support@<span>kitchenshelf.co.uk</span>
						</a>
					</motion.h1>
				</div>
				<div className="custom-shape-divider-bottom-1627895230">
					<svg
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1200 120"
						preserveAspectRatio="none"
					>
						<motion.path
							whileHover={{ scale: 1.1 }}
							d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
							opacity=".25"
							className="shape-fill"
						></motion.path>
						<motion.path
							whileHover={{ scale: 1.1 }}
							d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
							opacity=".5"
							className="shape-fill"
						></motion.path>
						<motion.path
							whileHover={{ scale: 1.1 }}
							d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
							className="shape-fill"
						></motion.path>
					</svg>
				</div>
				{/* <div className="custom-shape-divider-bottom-1627893832">
					<svg
						data-name="Layer 1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1200 120"
						preserveAspectRatio="none"
					>
						<path
							d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
							className="shape-fill"
						></path>
					</svg>
				</div> */}
			</ContactWrapper>
		</React.Fragment>
	);
};
const ContactWrapper = styled.section`
	height: 100vh;
	padding: 60px 20px;
	color: black;

	.hero-content {
		color: black;
		text-align: center;
		height: 90%;
		width: 100%;
		max-width: 400px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-left: auto;
		margin-right: auto;

		@media (min-width: 768px) {
			max-width: 650px;
		}

		h1 {
			font-size: 1.55rem;
			line-height: 1.2;
			font-weight: bold;
			span {
				background: -webkit-linear-gradient(45deg, #00cc99, #00cc99, #3f7cac);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			a {
				text-decoration: none;
				color: unset;
			}
		}

		h3 {
			margin: 0;
			line-height: 1.2;
			font-size: 1.3rem;
			font-weight: normal;

			@media (min-width: 768px) {
				font-size: 1.35rem;
			}

			@media (min-width: 1200px) {
				font-size: 1.5rem;
			}
		}

		@media (min-width: 768px) {
			max-width: 800px;

			h1 {
				font-size: 3rem;
			}
		}

		@media (min-width: 1200px) {
			h1 {
				font-size: 4rem;
			}
		}
	}

	.custom-shape-divider-bottom-1627895230 {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		overflow: hidden;
		line-height: 0;
		transform: rotate(180deg);
	}

	.custom-shape-divider-bottom-1627895230 svg {
		position: relative;
		display: block;
		width: calc(172% + 1.3px);
		height: 340px;
		transform: rotateY(180deg);
	}

	.custom-shape-divider-bottom-1627895230 .shape-fill {
		fill: #e7e7e7;
	}
`;
export default IndexPage;
